var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":_vm.colspan}},[_c('v-row',[_c('v-col',{class:{
        'pb-0': _vm.$vuetify.breakpoint.smAndDown,
      },attrs:{"cols":12,"md":4}},[_c('v-list',{attrs:{"dense":""}},[(_vm.name)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"lead-list__list--normal"},[_vm._v(" "+_vm._s(_vm.name)+" ")])],1)],1):_vm._e(),(_vm.lead.formattedPhone)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{attrs:{"href":`tel:${_vm.lead.formattedPhone}`}},[_vm._v(_vm._s(_vm.lead.formattedPhone))])])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"two-line":!_vm.lead.confirmedAt}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{attrs:{"href":`mailto:${_vm.lead.email}`}},[_vm._v(_vm._s(_vm.lead.email))])]),(!_vm.lead.confirmedAt)?_c('v-list-item-subtitle',[_vm._v(" Bestätigung ausstehend ")]):_vm._e()],1)],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp || _vm.valuationCount > 0)?_c('v-col',{class:{
        'pt-0': _vm.$vuetify.breakpoint.smAndDown,
      },attrs:{"cols":12,"md":5,"lg":6}},[[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getFirstValuationConversions(_vm.lead)),function(conversion,index){return _c('v-list-item',{key:conversion.id,attrs:{"two-line":""}},[_c('v-list-item-icon',[(_vm.lead.conversionCountByType.VALUATION > 1)?_c('v-icon',[_vm._v(" mdi-home-floor-"+_vm._s(index + 1)+" ")]):_vm._e(),_c('v-icon',[_vm._v(" mdi-home ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"lead-list__item-title--wrap"},[_c('strong',[_vm._v(" "+_vm._s(_vm.formatPropertyPrice(conversion.valuation))+" ")]),_vm._v(" "+_vm._s(' • ')+" "+_vm._s(_vm.formatPropertyAddress(conversion.valuation))+" "),(_vm.lead.conversionCountByType.VALUATION > 3 && index === 2)?_c('v-chip',{attrs:{"x-small":""}},[_vm._v(" + "+_vm._s(_vm.lead.conversionCountByType.VALUATION - 3)+" weitere ")]):_vm._e()],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.formatPropertyType(conversion.valuation))+" "),(conversion.valuation.inputs.subType)?_c('span',[_vm._v(" ("+_vm._s(_vm.formatPropertySubType(conversion.valuation))+") ")]):_vm._e()])],1)],1)}),1)]],2):_vm._e(),_c('v-col',{staticClass:"justify-end d-flex",class:{
        'pt-5': _vm.$vuetify.breakpoint.mdAndUp,
        'justify-center mb-4 pt-0' : _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"cols":12,"md":3,"lg":2}},[_c('v-btn',{attrs:{"color":"primary","to":`/leads/${_vm.lead.id}${_vm.routeHash || ''}`,"outlined":"","small":""}},[_vm._v(" Weitere Details ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }