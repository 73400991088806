export const DEFAULT_COLUMN_SETTINGS = [
  {
    value: 'name',
    text: 'Name',
    visible: true
  },
  {
    value: 'conversionCountByType.VALUATION',
    text: 'Immobilie bewertet',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.RETURN_CALL',
    text: 'Telefonische Beratung',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.LIFE_ANNUITY',
    text: 'Leibrenten-Check',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.PARTIAL_SALE',
    text: 'Teilverkaufs-Check',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversions.download.postalShipping',
    text: 'Postalischer Versand',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.PLOT_VALUATION',
    text: 'Grundstücksbewertung',
    visible: false,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.PROPERTY_REQUEST',
    text: 'Kaufgesuch',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversions.preConversion.type.QUIZ',
    text: 'Situationsanalyse',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'currentStatus',
    text: 'Status',
    visible: true
  },
  {
    value: 'createdAt',
    text: 'Erstellt',
    visible: false
  },
  {
    value: 'conversions',
    text: 'Aktionen',
    visible: true
  },
  {
    value: 'lastConversionAt',
    text: 'Letzte Aktion',
    visible: true
  },
  {
    value: 'city',
    text: 'Stadt',
    visible: false
  },
  {
    value: 'zip',
    text: 'PLZ',
    visible: false
  },
  {
    value: 'assignedUser',
    text: 'Mitarbeiter',
    visible: true
  },
  {
    value: 'confirmedAt',
    text: 'E-Mail-Adresse bestätigt',
    visible: false
  },
  {
    value: 'email',
    text: 'E-Mail-Adresse',
    visible: false
  },
  {
    value: 'exportedAt',
    text: 'Kontakt exportiert',
    visible: false
  }
]

export const DEFAULT_HEADERS = [
  {
    text: '',
    value: 'confirmedAt',
    sortable: false,
    width: '20px',
    filterable: false
  },
  {
    text: 'E-Mail-Adresse',
    value: 'email',
    sortable: false
  },
  {
    text: 'Name',
    value: 'name',
    sortable: false
  },
  {
    text: 'Erstellt',
    value: 'createdAt',
    filterable: false
  },
  {
    value: 'conversions',
    text: 'Aktionen',
    visible: true,
    filterable: false,
    sortable: false
  },
  {
    text: 'Letzte Aktion',
    value: 'lastConversionAt',
    filterable: false
  },
  {
    value: 'city',
    text: 'Stadt',
    filterable: false
  },
  {
    value: 'zip',
    text: 'PLZ',
    filterable: false
  },
  {
    text: 'Status',
    value: 'currentStatus',
    sortable: false
  },
  {
    text: 'Exportiert',
    value: 'exportedAt',
    filterable: false
  },
  {
    text: 'Mitarbeiter',
    value: 'assignedUser',
    sortable: true
  },
  {
    value: 'conversionCountByType.VALUATION',
    sortable: false
  },
  {
    value: 'conversionCountByType.RETURN_CALL',
    sortable: false
  },
  {
    value: 'conversionCountByType.LIFE_ANNUITY',
    sortable: false
  },
  {
    value: 'conversionCountByType.PARTIAL_SALE',
    sortable: false
  },
  {
    value: 'conversionCountByType.PROPERTY_REQUEST',
    sortable: false
  },
  {
    value: 'conversionCountByType.PLOT_VALUATION',
    sortable: false
  },
  {
    value: 'conversions.download.postalShipping',
    sortable: false
  },
  {
    value: 'actions',
    align: 'end',
    width: 1,
    sortable: false
  }
]

export const BASIC_COLUMN_SETTINGS = [
  {
    value: 'name',
    text: 'Name',
    visible: true
  },
  {
    value: 'conversionCountByType.VALUATION',
    text: 'Immobilie bewertet',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.RETURN_CALL',
    text: 'Telefonische Beratung',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.LIFE_ANNUITY',
    text: 'Leibrenten-Check',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.PARTIAL_SALE',
    text: 'Teilverkaufs-Check',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'conversionCountByType.PROPERTY_REQUEST',
    text: 'Kaufgesuch',
    visible: true,
    hideDisplay: true
  },
  {
    value: 'currentStatus',
    text: 'Status',
    visible: true
  },
  {
    value: 'createdAt',
    text: 'Erstellt',
    visible: false
  },
  {
    value: 'conversions',
    text: 'Aktionen',
    visible: true
  },
  {
    value: 'lastConversionAt',
    text: 'Letzte Aktion',
    visible: true
  },
  {
    value: 'city',
    text: 'Stadt',
    visible: false
  },
  {
    value: 'zip',
    text: 'PLZ',
    visible: false
  },
  {
    value: 'confirmedAt',
    text: 'E-Mail-Adresse bestätigt',
    visible: false
  },
  {
    value: 'email',
    text: 'E-Mail-Adresse',
    visible: false
  },
  {
    value: 'exportedAt',
    text: 'Kontakt exportiert',
    visible: false
  }
]

export const BASIC_HEADERS = [
  {
    text: '',
    value: 'confirmedAt',
    sortable: false,
    width: '20px',
    filterable: false
  },
  {
    text: 'E-Mail-Adresse',
    value: 'email',
    sortable: false
  },
  {
    text: 'Name',
    value: 'name',
    sortable: false
  },
  {
    text: 'Erstellt',
    value: 'createdAt',
    filterable: false
  },
  {
    value: 'conversions',
    text: 'Aktionen',
    visible: true,
    filterable: false,
    sortable: false
  },
  {
    text: 'Letzte Aktion',
    value: 'lastConversionAt',
    filterable: false
  },
  {
    value: 'city',
    text: 'Stadt',
    filterable: false
  },
  {
    value: 'zip',
    text: 'PLZ',
    filterable: false
  },
  {
    text: 'Status',
    value: 'currentStatus',
    sortable: false
  },
  {
    text: 'Exportiert',
    value: 'exportedAt',
    filterable: false
  },
  {
    value: 'conversionCountByType.VALUATION',
    sortable: false
  },
  {
    value: 'conversionCountByType.RETURN_CALL',
    sortable: false
  },
  {
    value: 'conversionCountByType.LIFE_ANNUITY',
    sortable: false
  },
  {
    value: 'conversionCountByType.PARTIAL_SALE',
    sortable: false
  },
  {
    value: 'conversionCountByType.PROPERTY_REQUEST',
    sortable: false
  },
  {
    value: 'actions',
    align: 'end',
    width: 1,
    sortable: false
  }
]
