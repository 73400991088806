<template>
  <div
    class="lead-list"
    :style="cssProps"
  >
    <!-- List Options -->
    <div
      v-if="!disableFilters"
      class="mb-4 list-options mb-sm-2"
    >
      <v-card outlined>
        <v-card-text
          class="flex-wrap w-full d-flex pa-0"
          :class="{
            'justify-space-between':($vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.xs),
            'justify-center':$vuetify.breakpoint.sm,
          }"
        >
          <div
            class="d-flex"
            :class="{'w-full justify-space-between flex-column':$vuetify.breakpoint.xs} "
          >
            <!-- Search -->
            <div
              class="pa-2 flex-grow-1 lead-list__search"
              outlined
            >
              <v-text-field
                v-model="listOptions.search"
                class="mt-2"
                label="Suche nach Name, Adresse, E-Mail"
                hide-details
                single-line
                clearable
                solo
                flat
                dense
                outlined
                append-icon="mdi-magnify"
              />
            </div>

            <!-- Filter -->
            <div
              class="d-flex align-center px-2 option-container"
              :class="{'w-full':$vuetify.breakpoint.xs}"
            >
              <v-badge
                :value="hasFilterBadge"
                :content="activeFiltersCount"
                color="red"
                overlap
                :class="{'w-full':$vuetify.breakpoint.xs}"

                right
              >
                <v-btn
                  outlined
                  color="primary"
                  class="camel-case option-btn"
                  @click="toggleLeadListTool('filter')"
                >
                  <v-icon left>
                    mdi-filter-variant
                  </v-icon>
                  Filter
                </v-btn>
              </v-badge>
            </div>
          </div>

          <v-spacer v-if="!isLeadImportTable" />

          <!-- Expanded Actions for Desktop -->
          <div
            v-if="$vuetify.breakpoint.width > fullHeaderBreakpoint"
            class="d-flex align-center"
          >
            <!-- Create new Lead -->
            <create-lead-dialog
              v-if="isExpert || isLight"
              class="w-full"
              :refetch-parent="refetchParent"
              :company="company"
            >
              <template v-slot:activator>
                <v-btn
                  outlined
                  color="primary"
                  class="camel-case mx-2"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-account-plus
                  </v-icon>
                  Kontakt anlegen
                </v-btn>
              </template>
            </create-lead-dialog>
            <!-- Import -->
            <Feature
              :feature-slug="featureNames.LEAD_IMPORT"
            >
              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                outlined
                color="primary"
                class="camel-case mx-2"
                @click="showContactImportDialog = true"
              >
                <v-icon
                  small
                  left
                >
                  mdi-upload
                </v-icon>
                Importieren
              </v-btn>
            </Feature>
            <!-- Acquisition Booster -->
            <Feature
              :feature-slug="featureNames.ACQUISITION_BOOSTER"
            >
              <v-tooltip
                :disabled="isHoppermationActivated"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      :disabled="!isHoppermationActivated"
                      outlined
                      color="primary"
                      class="camel-case mx-2"
                      @click="toggleLeadListTool('invitation')"
                    >
                      <v-icon
                        small
                        left
                      >
                        mdi-rocket-launch-outline
                      </v-icon>
                      Akquise-Booster
                    </v-btn>
                  </div>
                </template>
                <span>Um den Akquise-Booster zu nutzen, aktivieren Sie den neuen E-Mail-Funnel.</span>
              </v-tooltip>
            </Feature>
          </div>

          <!-- Actions -->
          <div
            class="justify-end d-flex align-center"
            :class="{'w-full justify-space-between flex-column':$vuetify.breakpoint.xs} "
          >
            <!-- More Functions -->
            <div
              v-if="$vuetify.breakpoint.width < fullHeaderBreakpoint && moreActionsCount"
              class="px-2 option-container d-flex align-center"
              :class="{'w-full':$vuetify.breakpoint.xs}"
            >
              <v-menu
                bottom
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="px-2 camel-case option-btn"

                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>
                      mdi-dots-vertical
                    </v-icon>
                    Mehr
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="list-item-container">
                    <create-lead-dialog
                      v-if="isExpert || isLight"
                      class="w-full"
                      :refetch-parent="refetchParent"
                      :company="company"
                    >
                      <template v-slot:activator>
                        <div class="w-full menu-list-item">
                          <v-icon
                            small
                            left
                            color="primary"
                          >
                            mdi-account-plus
                          </v-icon>
                          Kontakt anlegen
                        </div>
                      </template>
                    </create-lead-dialog>
                  </v-list-item>
                  <Feature
                    :feature-slug="featureNames.LEAD_IMPORT"
                  >
                    <v-list-item
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="list-item-container"
                    >
                      <div
                        class="w-full menu-list-item camel-case"
                        @click="showContactImportDialog = true"
                      >
                        <v-icon
                          small
                          left
                          color="primary"
                        >
                          mdi-upload
                        </v-icon>
                        Kontakte importieren
                      </div>
                    </v-list-item>
                  </Feature>
                  <Feature
                    :feature-slug="featureNames.ACQUISITION_BOOSTER"
                  >
                    <v-list-item
                      :disabled="!isHoppermationActivated"
                      @click="toggleLeadListTool('invitation')"
                    >
                      <div class="d-flex align-center">
                        <v-icon
                          small
                          left
                          color="primary"
                        >
                          mdi-rocket-launch-outline
                        </v-icon>
                        Akquise-Booster
                      </div>
                    </v-list-item>
                  </Feature>
                </v-list>
              </v-menu>
            </div>

            <slot name="export" />

            <!-- Column Settings -->
            <div
              class="px-2 option-container d-flex align-center"
              :class="{'w-full':$vuetify.breakpoint.xs}"
            >
              <v-btn
                outlined
                color="primary"
                class="camel-case option-btn"
                @click="toggleLeadListTool('column')"
              >
                <v-icon left>
                  mdi-view-column
                </v-icon>
                Ansicht
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <!-- Lead List -->
    <div
      class="d-flex"
      :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
      :style="{'gap': isAdditionalFilterOpen ? '20px' : hasActiveLeadListTool ? '10px' : '0px'}"
    >
      <v-slide-x-transition>
        <div
          v-if="hasActiveLeadListTool"
          class="d-flex"

          :style="{
            'gap': isAdditionalFilterOpen ? '10px' : '0px',
            'max-height': $vuetify.breakpoint.lgAndDown ? 'calc(100vh - 245px)' : '1150px'
          }"

          :class="{
            'list-tool-width':$vuetify.breakpoint.mdAndUp && !isAdditionalFilterOpen,
            'list-tool-double-width':$vuetify.breakpoint.mdAndUp && isAdditionalFilterOpen,
            'w-full':$vuetify.breakpoint.smAndDown
          }"
        >
          <BulkInvitation
            v-if="leadListOptionDetails.invitation.isOpen"
            class="h-full"
            :class="{
              'list-tool-width':$vuetify.breakpoint.mdAndUp,
              'w-full':$vuetify.breakpoint.smAndDown
            }"
            style="overflow:scroll"
            :selected-items-length="selectedItemsLength"
            :trigger-reset-acquisition-booster="triggerResetAcquisitionBooster"
            @triggeredResetAcquisitionBooster="triggeredResetAcquisitionBooster"
            @close="toggleLeadListTool('invitation')"
            @updateInvitation="updateInvitation"
            @sendInvitation="sendInvitation"
          >
            <template slot="selectAction">
              <slot name="selectAction" />
            </template>
            <template slot="selectAll">
              <v-card
                outlined
                style="border-radius: 10px !important"
              >
                <v-card-text class="pa-2">
                  <div v-if="!isSelectAllLeadsVisible">
                    <span> <b>{{ selectedItemsLength }}</b> Kontakt(e) ausgewählt.</span>
                  </div>
                  <div v-else-if="isAllLeadsSelected">
                    <span>Alle <b>{{ selectedItemsLength }}</b> Kontakte ausgewählt.</span>

                    <v-btn
                      depressed
                      text
                      small
                      color="primary"
                      class="w-full mt-2"
                      @click="deselectAllItems"
                    >
                      Auswahl aufheben
                    </v-btn>
                  </div>
                  <div v-else>
                    <span> <b>{{ selectedItemsLength }}</b> Kontakt(e) auf der aktuellen Seite ausgewählt.</span>
                    <v-btn
                      depressed
                      text
                      small
                      color="primary"
                      class="w-full mt-2"
                      :loading="isAllLeadsLoading"
                      :disabled="isAllLeadsLoading"
                      @click="selectAllItems"
                    >
                      Alle gefilterten Kontakte auswählen
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </BulkInvitation>
          <FilterSettings
            v-if="leadListOptionDetails.filter.isOpen"
            :has-tool="hasTool"
            :assigned-user-list="assignedUserList"
            :invited-landingpage-slugs="invitedLandingpageList"
            :traffic-source-list="trafficSourceList"
            :download-landingpage-list="downloadLandingpages"
            :can-close="leadListOptionDetails.filter.isOpen && !leadListOptionDetails.invitation.isOpen"
            filter-button-label="Weitere Filter"
            class="h-full"
            :class="{
              'list-tool-width':$vuetify.breakpoint.mdAndUp,
              'w-full':$vuetify.breakpoint.smAndDown
            }"
            @filtersChanged="onFiltersChanged"
            @close="toggleLeadListTool('filter')"
          />
          <ColumnSettingsTool
            v-if="leadListOptionDetails.column.isOpen"
            :has-tool="hasTool"
            class="h-full"
            :class="{
              'list-tool-width':$vuetify.breakpoint.mdAndUp,
              'w-full':$vuetify.breakpoint.smAndDown
            }"
            @columnSettingsChanged="onColumnSettingsChanged"
            @close="toggleLeadListTool('column')"
          />
        </div>
      </v-slide-x-transition>

      <List
        :class="{
          'list-width':$vuetify.breakpoint.mdAndUp && hasActiveLeadListTool && !isAdditionalFilterOpen,
          'list-double-width':$vuetify.breakpoint.mdAndUp && isAdditionalFilterOpen,
          'w-full':$vuetify.breakpoint.smAndDown
        }"
        :leads="leads"
        :show-select="showListSelect"
        :loading="loading"
        :server-items-length="serverItemsLength"
        :select-items="selectListItems"
        :trigger-select-all-items="triggerSelectAllItems"
        :trigger-deselect-all-items="triggerDeselectAllItems"
        :list-options="listOptions"
        :active-filters-count="activeFiltersCount"
        :column-settings="columnSettings"
        :title="title"
        @allVisibleItemsSelected="allVisibleItemsSelected"
        @allItemsSelected="allItemsSelected"
      />
    </div>
    <import
      :refetch-parent="refetchParent"
      :show-import-dialog="showContactImportDialog"
      :company="company"
      @cancel="showContactImportDialog=false"
    />
  </div>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import { isExpertUser, isLightUser } from '@/lib/productTypes'
import { sortByString } from '@/lib/sort-functions'
import USERS from '../queries/Users.gql'
import MAIL_PROVIDER from '../queries/MailProvider.gql'
import LANDINGPAGE_SLUGS_USED_FOR_INVITATION from '../queries/LandingpageSlugsUsedForInvitation.gql'
import TOTAL_TRAFFIC_BY_SOURCE_FOR_COMPANY from '../queries/TotalTrafficBySourceForCompany.gql'
import branding from '@/mixins/branding'
import List from './List.vue'
import { BASIC_COLUMN_SETTINGS, DEFAULT_COLUMN_SETTINGS } from '../configs'
import { getActiveFilterCount, restoreAllFilters } from '../helper/filter'
import { TOOLS_WITH_DOWNLOADS } from '@/lib/tools'
import { InvitationContactFilter } from '../enums/Invitation'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'

const FULL_HEADER_BREAKPOINT = 1400
const FULL_HEADER_BREAKPOINT_SMALL = 960

export default {
  components: {
    Import: () => import(/* webpackChunkName: "import" */ '../Import'),
    CreateLeadDialog: () => import('../CreateLeadDialog'),
    FilterSettings: () => import('./FilterSettings'),
    BulkInvitation: () => import('./BulkInvitation'),
    ColumnSettingsTool: () => import('./ColumnSettingsTool'),
    List,
    Feature
  },

  mixins: [currencyMixin, branding],

  props: {
    parentTriggerSelectAllItems: {
      type: Boolean,
      default: false
    },
    leads: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableFilters: {
      type: Boolean,
      default: false
    },
    serverItemsLength: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    refetchLeads: {
      type: Function,
      default: () => {}
    },
    selectAllLeadsState: {
      type: String,
      default: 'idle'
    },
    selectedItemsLength: {
      type: Number,
      default: 0
    },
    selectItems: {
      type: Function,
      default: () => {}
    },
    isLeadImportTable: {
      type: Boolean,
      default: false
    },
    landingpageTypes: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    triggerResetAcquisitionBooster: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      activeFiltersCount: 0,
      columnSettings: isExpertUser(this.$auth.user) ? DEFAULT_COLUMN_SETTINGS : BASIC_COLUMN_SETTINGS,
      listOptions: {
        options: {
          sortDesc: [true],
          itemsPerPage: this.itemsPerPage
        },
        search: '',
        filters: { },
        landingpageSlugFilter: ''
      },
      upsellingPropertyTrackerDialog: false,
      showContactImportDialog: false,
      leadListOptionDetails: {
        filter: {
          isOpen: false,
          close: () => this.removeFilterParam()
        },
        invitation: {
          isOpen: false,
          open: () => this.showInvitationLeads(),
          close: () => this.closeInvitationLeads()
        },
        column: {
          isOpen: false
        }
      },
      triggerSelectAllItems: false,
      triggerDeselectAllItems: false,
      isSelectAllLeadsVisible: false,
      showListSelect: false,
      allVisibleItemsChecked: false,
      featureNames
    }
  },

  computed: {
    isHoppermationActivated () {
      return this.mailProvider === 'HOPPERMATION'
    },
    isAdditionalFilterOpen () {
      return this.leadListOptionDetails.invitation.isOpen && this.leadListOptionDetails.filter.isOpen
    },
    fullHeaderBreakpoint () {
      return this.moreActionsCount > 1 ? FULL_HEADER_BREAKPOINT : FULL_HEADER_BREAKPOINT_SMALL
    },
    moreActionsCount () {
      return this.isExpert + this.isLeadImportTable || this.isLight + this.isLeadImportTable
    },

    assignedUserList () {
      const assignedUserList = (this.company?.users ?? [])
        .map(user => ({
          value: user.id,
          text: `${user.firstname} ${user.lastname}`
        }))
        .sort(sortByString('text'))

      return [{
        value: 'unassigned',
        text: 'Nicht zugewiesen'
      }, ...assignedUserList]
    },

    invitedLandingpageList () {
      const landingpageName = (slug) => {
        const landingpage = this.company?.landingpages?.find(({ slug: landingpageSlug }) => landingpageSlug === slug)
        return landingpage?.name ?? slug
      }

      const invitedLandingapgeList = this.invitedLandingpageSlugs?.map(slug => ({
        value: slug,
        text: landingpageName(slug)
      })).sort(sortByString('text'))

      return [{
        value: 'ALL',
        text: 'Alle bereits Eingeladenen'
      }, {
        value: 'NONE',
        text: 'Alle bislang nicht Eingeladenen'
      },
      { header: 'Bereits eingeladen zu:' },
      ...invitedLandingapgeList]
    },

    trafficSourceList () {
      return this.totalTrafficBySourceForCompany
        .filter(({ source }) => Boolean(source))
        .map(({ source }) => ({
          value: source,
          text: source
        })).sort(sortByString('text'))
    },

    downloadLandingpages () {
      return this.company?.landingpages?.filter(({ type }) => type === 'GUIDE' || type === 'CHECKLIST')
        .map(({ name, slug }) => ({
          value: slug,
          text: name
        })).sort(sortByString('text'))
    },

    isExpert () {
      return isExpertUser(this.$auth.user)
    },

    isLight () {
      return isLightUser(this.$auth.user)
    },

    hasFilterBadge () {
      return this.activeFiltersCount > 0
    },

    hasActiveLeadListTool () {
      return Object.keys(this.leadListOptionDetails).some(key => this.leadListOptionDetails[key].isOpen)
    },

    availableTools () {
      return TOOLS_WITH_DOWNLOADS.filter(({ type }) => this.hasTool(type))
    },
    cssProps () {
      return {
        '--theme-color-red': this.$vuetify.theme.themes.light.red
      }
    },

    isAllLeadsSelected () {
      return this.selectAllLeadsState === 'selected'
    },
    isAllLeadsLoading () {
      return this.selectAllLeadsState === 'loading'
    }
  },

  watch: {
    parentTriggerSelectAllItems () {
      if (this.parentTriggerSelectAllItems && this.isAdditionalFilterOpen) {
        this.triggerSelectAllItems = true
        this.$emit('triggeredSelectAllItems')
      }
    },
    listOptions: {
      handler () {
        this.triggerDeselectAllItems = true
        this.triggerSelectAllItems = false

        // Sort options
        const { sortBy, sortDesc, page, itemsPerPage } = this.listOptions.options

        // Search
        let searchString = ''
        if (this.listOptions.search && this.listOptions.search.length > 2) {
          searchString = this.listOptions.search
        }

        this.refetchLeads({
          sortBy: sortBy[0],
          sortDesc: sortDesc[0],
          page,
          itemsPerPage,
          search: searchString,
          filters: this.listOptions.filters,
          landingpageSlugFilter: this.listOptions.landingpageSlugFilter
        })
      },
      deep: true
    }
  },

  apollo: {
    company: {
      query: USERS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    mailProvider: {
      query: MAIL_PROVIDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: (data) => data.company.mailProvider
    },
    invitedLandingpageSlugs: {
      query: LANDINGPAGE_SLUGS_USED_FOR_INVITATION
    },
    totalTrafficBySourceForCompany: {
      query: TOTAL_TRAFFIC_BY_SOURCE_FOR_COMPANY
    }
  },

  created () {
    const filters = restoreAllFilters({ isExpert: this.isExpert, availableTools: this.availableTools })
    const activeFiltersCount = getActiveFilterCount({ isExpert: this.isExpert, availableTools: this.availableTools })
    this.onFiltersChanged({ filters, activeFiltersCount })
  },

  mounted () {
    this.setInitialFilter()
  },

  methods: {
    triggeredResetAcquisitionBooster () {
      this.$emit('triggeredResetAcquisitionBooster')
    },
    sendInvitation () {
      this.$emit('sendInvitation')
    },
    selectListItems (data) {
      this.selectItems(data)
      if (this.allVisibleItemsChecked) {
        if (data.length < this.serverItemsLength) {
          this.isSelectAllLeadsVisible = true
        } else {
          this.isSelectAllLeadsVisible = false
          this.triggerSelectAllItems = false
        }

        this.allVisibleItemsChecked = false
      } else {
        this.isSelectAllLeadsVisible = false
        this.triggerSelectAllItems = false
      }
    },
    updateInvitation (selectData) {
      this.listOptions.landingpageSlugFilter = selectData.landingpageSlug

      if (selectData.contactFilter === InvitationContactFilter.CUSTOM) {
        this.showListSelect = true
        this.showAdditionFilter(true)
        this.$emit('updateInvitationParameters', selectData)
      } else {
        this.showListSelect = false
        this.showAdditionFilter(false)
        this.deselectAllItems()

        const filters = restoreAllFilters({ isExpert: this.isExpert, availableTools: this.availableTools })
        const activeFiltersCount = getActiveFilterCount({ isExpert: this.isExpert, availableTools: this.availableTools })
        this.activeFiltersCount = activeFiltersCount
        this.listOptions.search = ''
        this.$emit('updateInvitationParameters', { ...selectData, filters })
        this.onFiltersChanged({ filters, activeFiltersCount })
      }
    },
    setInitialFilter () {
      if (Object.keys(this.$route.query).includes('filter')) {
        this.leadListOptionDetails.filter.isOpen = true
      }
    },
    showAdditionFilter (show) {
      const details = this.leadListOptionDetails.filter
      if (show) {
        details.isOpen = true
        if ('open' in details) details.open()
      } else {
        details.isOpen = false
        if ('close' in details) details.close()
      }
    },
    toggleLeadListTool (slug) {
      if (this.isAdditionalFilterOpen) {
        const details = this.leadListOptionDetails.invitation
        const detailsFilter = this.leadListOptionDetails.filter
        details.isOpen = false
        detailsFilter.isOpen = false
        if ('close' in details) details.close()
        if ('close' in detailsFilter) detailsFilter.close()
        return
      }
      const details = this.leadListOptionDetails[slug]
      if (details.isOpen) {
        details.isOpen = false
        if ('close' in details) details.close()
      } else {
        Object.keys(this.leadListOptionDetails).forEach(key => {
          if (this.leadListOptionDetails[key].isOpen) {
            this.leadListOptionDetails[key].isOpen = false
            if ('close' in this.leadListOptionDetails[key]) this.leadListOptionDetails[key].close()
          }
        })
        details.isOpen = true
        if ('open' in details) details.open()
      }
    },
    onColumnSettingsChanged (columnSettings) {
      this.columnSettings = columnSettings
    },
    onFiltersChanged ({ filters, activeFiltersCount }) {
      this.listOptions.filters = { ...this.listOptions.filters, ...filters }
      this.activeFiltersCount = activeFiltersCount
    },
    showUpsellingPropertyTrackerDialog () {
      this.upsellingPropertyTrackerDialog = true
    },
    refetchParent () {
      this.listOptions.options.sortBy = ['createdAt']
      const { sortBy, sortDesc, page, itemsPerPage, search } = this.listOptions.options
      this.refetchLeads({ sortBy: sortBy[0], sortDesc: sortDesc[0], page, itemsPerPage, search, filters: this.listOptions.filters })
    },
    hasTool (toolType) {
      return toolType === 'DOWNLOAD' || this.landingpageTypes.some(lT => lT.type === toolType)
    },

    showInvitationLeads () {
      this.listOptions.filters.leadOriginConversion = true
      this.listOptions.filters.leadOriginImport = true
    },
    closeInvitationLeads () {
      this.listOptions.filters.leadOriginConversion = true
      this.listOptions.filters.leadOriginImport = this.isLeadImportTable
      this.triggerDeselectAllItems = true
      this.triggerSelectAllItems = false
      this.isSelectAllLeadsVisible = false
      this.showListSelect = false
    },
    allVisibleItemsSelected ({ checked }) {
      this.allVisibleItemsChecked = checked
    },
    allItemsSelected (value) {
      this.triggerDeselectAllItems = false
      this.$emit('allItemsSelected', value)
    },
    removeFilterParam () {
      if (Object.keys(this.$route.query).includes('filter')) {
        this.$router.push('/leads')
      }
    },
    selectAllItems () {
      this.triggerSelectAllItems = true
    },
    deselectAllItems () {
      this.triggerDeselectAllItems = true
      this.triggerSelectAllItems = false
    }

  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
  -webkit-box-shadow:none;
}

.option-container{
  height: 50px;
}

.option-btn  {
  width: 100%;
}

.option-btn .v-btn__content {
  padding-left: 10px;
  padding-right: 10px;
}

/* breakpoint md and up */
@media (min-width: 600px) {

  .lead-list__search{
    min-width: 350px;
    max-width: 350px;
  }

  .option-container {
    max-width: 120px;
    height: 100%;
  }
}

.lead-list__item {
  cursor: pointer;
}

.lead-list__detail-btn {
  visibility: hidden;
}

.lead-list__item:hover .lead-list__detail-btn {
  visibility: visible;
}

.lead-list__item--new > td:first-child {
  border-left: 3px solid var(--theme-color-red);
}

.lead-list__item-title--wrap {
  white-space: normal;
}

.lead-list .v-list--dense .v-list-item .v-list-item__title,
.lead-list .v-list--dense .v-list-item .v-list-item__subtitle {
  font-weight: normal;
}

.lead-list__draggable {
  cursor: move;
}

.lead-list__preview {
  display: flex;
  align-items: center;
}

.lead-list__preview-item {
  margin-right: 16px;
}

.lead-list__preview-item:last-child {
  margin-right: none;
}

.camel-case {
    text-transform: unset !important;
    font-weight: normal;
}

.list-options .v-input--selection-controls .v-input__slot, .v-input--select{
  margin-bottom: 0px;
}

.list-options .v-text-field.v-text-field--enclosed{
  margin-top:0px !important;
}

.activate-window-step{
  max-width: 600px
}

.list-item-container{
  padding: 0px;
}

.list-item-container:hover{
  background-color: rgb(245, 245, 245);
}

.menu-list-item{
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  display:flex;
  align-items:center;
  cursor: pointer;
}

.filter-container{
  position:relative;
}

.disabled-layer{
  height: 100%;
  width: 100%;
  cursor:pointer;
  position: absolute;
}

.lead-list .v-list--dense .v-list-item .v-list-item__title, .lead-list .v-list--dense .v-list-item .v-list-item__subtitle{
  max-width: 200px;
  overflow: hidden;
}

.lead-list__item-mobile--new{
  border-left: 3px solid var(--theme-color-red);
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 25px;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:first-child{
   min-height: 48px;
}

.leadlist-icon-wrapper{
  min-width: 25px;
  width: 25px;
  max-width: 25px;
}

.list-tool-width{
  min-width: 360px;
  max-width: 360px;
}

.list-tool-double-width{
  min-width: 720px;
  max-width: 720px;
}

.list-width{
  min-width: calc(100% - 360px - 10px);
  max-width: calc(100% - 360px - 10px);
}

.list-double-width{
  min-width: calc(100% - 720px - 20px);
  max-width: calc(100% - 720px - 20px);
}
</style>
