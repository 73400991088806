<template>
  <td :colspan="colspan">
    <v-row>
      <v-col
        :cols="12"
        :md="4"
        :class="{
          'pb-0': $vuetify.breakpoint.smAndDown,
        }"
      >
        <v-list dense>
          <v-list-item v-if="name">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="lead-list__list--normal">
                {{ name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="lead.formattedPhone">
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a :href="`tel:${lead.formattedPhone}`">{{ lead.formattedPhone }}</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :two-line="!lead.confirmedAt">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a :href="`mailto:${lead.email}`">{{ lead.email }}</a>
              </v-list-item-title>
              <v-list-item-subtitle v-if="!lead.confirmedAt">
                Bestätigung ausstehend
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col
        v-if="$vuetify.breakpoint.mdAndUp || valuationCount > 0"
        :cols="12"
        :md="5"
        :lg="6"
        :class="{
          'pt-0': $vuetify.breakpoint.smAndDown,
        }"
      >
        <template>
          <v-list dense>
            <v-list-item
              v-for="(conversion, index) in getFirstValuationConversions(lead)"
              :key="conversion.id"
              two-line
            >
              <v-list-item-icon>
                <v-icon v-if="lead.conversionCountByType.VALUATION > 1">
                  mdi-home-floor-{{ index + 1 }}
                </v-icon>
                <v-icon>
                  mdi-home
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="lead-list__item-title--wrap">
                  <strong>
                    {{ formatPropertyPrice(conversion.valuation) }}
                  </strong>
                  {{ ' • ' }}
                  {{ formatPropertyAddress(conversion.valuation) }}

                  <v-chip
                    v-if="lead.conversionCountByType.VALUATION > 3 && index === 2"
                    x-small
                  >
                    + {{ lead.conversionCountByType.VALUATION - 3 }} weitere
                  </v-chip>
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ formatPropertyType(conversion.valuation) }}
                  <span v-if="conversion.valuation.inputs.subType">
                    ({{ formatPropertySubType(conversion.valuation) }})
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-col>

      <v-col
        :cols="12"
        :md="3"
        :lg="2"
        class="justify-end d-flex"
        :class="{
          'pt-5': $vuetify.breakpoint.mdAndUp,
          'justify-center mb-4 pt-0' : $vuetify.breakpoint.smAndDown
        }"
      >
        <v-btn
          color="primary"
          :to="`/leads/${lead.id}${routeHash || ''}`"
          outlined
          small
        >
          Weitere Details
        </v-btn>
      </v-col>
    </v-row>
  </td>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import { propertyTypes, propertySubTypes } from '@/lib/propertyTypes'

export default {
  mixins: [currencyMixin],

  props: {
    lead: {
      type: Object,
      required: true
    },
    colspan: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    valuationCount: {
      type: Number,
      required: true
    }
  },

  computed: {
    routeHash () {
      if (this.$route?.hash) {
        return this.$route.hash
      }
      return false
    }
  },

  methods: {

    getFirstValuationConversions ({ conversions }) {
      const valuations = conversions.filter(conversion => conversion.valuation)
      if (!valuations) {
        return []
      }
      return valuations.slice(0, 3)
    },

    formatPropertyPrice (valuation) {
      const avg = valuation.results?.property?.value
      const currency = valuation.results?.currency
      return avg && currency && this.formatPrice(avg, currency)
    },

    formatPropertyAddress (valuation) {
      const {
        street,
        houseNumber,
        city,
        zip
      } = valuation.inputs.address

      return `${street} ${houseNumber}, ${zip} ${city}`
    },

    formatPropertyType ({ inputs }) {
      return propertyTypes[inputs.type]
    },
    formatPropertySubType ({ inputs }) {
      return propertySubTypes[inputs.subType]
    }
  }
}
</script>
