<template>
  <v-row
    class="ma-0"
    style="max-width:100%"
  >
    <v-col
      class="pa-0"
      style="max-width:100%"
    >
      <v-card
        outlined
        class="pt-6"
        style="max-width:100%"
      >
        <h2
          v-if="title"
          class="pl-4 mb-3 title"
        >
          {{ title }}
        </h2>

        <v-data-table
          ref="table"
          :headers="headers"
          :items="leads"
          :loading="loading"
          loading-text="Daten werden geladen …"
          :no-data-text="noDataText"
          :footer-props="{
            itemsPerPageOptions,
            disableItemsPerPage: false,
            disablePagination: false,
            showCurrentPage: true,
            showFirstLastPage: true
          }"
          :items-per-page="listOptions.itemsPerPage"
          single-expand
          show-expand
          :show-select="showSelect"
          :expanded.sync="expandedRows"
          :item-class="getRowClass"
          :server-items-length="serverItemsLength"
          :options.sync="listOptions.options"
          :height="isSmallScreen ? 'calc(100vh - 330px)' : '1050px'"
          fixed-header
          @click:row="toggleRow"
          @input="selectItems"
          @toggle-select-all="selectAllVisibleItems"
        >
          <!-- Headers -->
          <!-- eslint-disable vue/valid-v-slot -->
          <template v-slot:header.assignedUser>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">Mitarbeiter</span>
              </template>
              <span>Verantwortlicher Mitarbeiter</span>
            </v-tooltip>
          </template>

          <template v-slot:header.city>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">Stadt</span>
              </template>
              <span>Stadtname der aktuellsten Immobilienbewertung</span>
            </v-tooltip>
          </template>

          <template v-slot:header.zip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">PLZ</span>
              </template>
              <span>PLZ der aktuellsten Immobilienbewertung</span>
            </v-tooltip>
          </template>

          <template v-slot:header.data-table-select="{ on, props }">
            <v-simple-checkbox
              color="primary"
              v-bind="props"
              :ripple="false"
              v-on="on"
            />
          </template>

          <!-- Items -->
          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              v-if="!item.invitationBlockedReason"
              :ripple="false"
              :value="isSelected"
              @input="select($event)"
            />
            <v-tooltip
              v-else
              top
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-alert-circle-check-outline
                </v-icon>
              </template>
              <span>{{ readableInvitationBlockedReason(item) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.exportedAt="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  style="min-width: 80px"
                  v-on="on"
                >
                  <v-icon v-if="item.exportedAt">
                    mdi-check
                  </v-icon>
                </div>
              </template>
              <span v-if="item.exportedAt">{{ formatDate(item.exportedAt) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  style="min-width:60px"
                  v-on="on"
                >
                  <span>{{ formatDate(item.createdAt, 'dateShort') }}</span>
                </div>
              </template>
              <span>{{ formatDate(item.createdAt) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.lastConversionAt="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  style="min-width:100px"
                  v-on="on"
                >
                  <span>{{ formatDate(item.lastConversionAt, 'dateShort') }}</span>
                </div>
              </template>
              <span>{{ formatDate(item.lastConversionAt) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.name="{ item }">
            <v-tooltip
              v-if="!formatName(item)"
              top
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <span
                    class="grey--text d-inline-block text-truncate"
                    style="max-width: 150px;"
                  >{{ item.email }}</span>
                </span>
              </template>
              {{ item.email }}
            </v-tooltip>

            {{ formatName(item) }}
          </template>

          <template v-slot:item.conversions="{ item }">
            <div>
              <div class="d-flex justify-space-between">
                <!-- Conversion-Types -->
                <div
                  v-for="{ type, label } of conversionTypes"
                  :key="type"
                  class="leadlist-icon-wrapper"
                >
                  <div v-if="showIcon(item, type)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          :color="isPropertyRequestUrgent(item,type)?'red' :'gray'"
                          v-on="on"
                        >
                          {{ getIcon(item, type) }}
                        </v-icon>
                      </template>
                      <span>{{ label }} <br>{{ isPropertyRequestUrgent(item,type) ? "Start so schnell wie möglich" :'' }}</span>
                    </v-tooltip>
                  </div>
                </div>

                <!-- Property Tracker -->
                <div class="leadlist-icon-wrapper">
                  <!-- Property Tracker invitation -->
                  <div v-if="showPropertyTrackerIcon(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-email-fast-outline
                        </v-icon>
                      </template>
                      <span>Eigentums-Radar Einladung versendet</span>
                    </v-tooltip>
                  </div>

                  <!-- Property Tracker finished by lead -->
                  <div v-if="showPropertyTrackerFinishedIcon(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          mdi-radar
                        </v-icon>
                      </template>
                      <span>Eigentums-Radar ausgeführt</span>
                    </v-tooltip>
                  </div>
                </div>

                <!-- Postal Shipping -->
                <div
                  v-if="isColumnVisible('conversions.download.postalShipping')"
                  class="leadlist-icon-wrapper"
                >
                  <div v-if="showPostalShippingIcon(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          <template> mdi-email-newsletter </template>
                        </v-icon>
                      </template>
                      <span>Dokument per Post angefordert</span>
                    </v-tooltip>
                  </div>
                </div>

                <!-- Quiz PreConversion -->
                <div
                  v-if="isColumnVisible('conversions.preConversion.type.QUIZ')"
                  class="leadlist-icon-wrapper"
                >
                  <div v-if="showQuizPreConversionIcon(item)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          <template> mdi-home-analytics </template>
                        </v-icon>
                      </template>
                      <span>Situationsanalyse durchgeführt</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:item.currentStatus="{ item }">
            {{ leadStatus[item.currentStatus] }}
          </template>

          <template v-slot:item.assignedUser="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  style="min-width: 80px"
                  v-on="on"
                >
                  <v-avatar
                    v-if="item.assignedUser"
                    color="grey"
                    size="30"
                  >
                    <span class="white--text caption font-weight-bold">
                      {{ getInitials(item.assignedUser) }}
                    </span>
                  </v-avatar>
                </div>
              </template>
              <span v-if="item.assignedUser">{{ formatName(item.assignedUser) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.confirmedAt="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="item.confirmedAt"
                  color="success"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else
                  color="orange lighten-2"
                  v-on="on"
                >
                  mdi-clock
                </v-icon>
              </template>
              <span v-if="item.confirmedAt">E-Mail bestätigt</span>
              <span v-else>E-Mail-Bestätigung ausstehend</span>
            </v-tooltip>
          </template>

          <template v-slot:item.email="{ item }">
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 150px;"
                  >{{ item.email }}</span>
                </span>
              </template>
              {{ item.email }}
            </v-tooltip>
          </template>

          <template v-slot:item.city="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  class="text-truncate"
                  style="max-width: 120px"
                  v-on="on"
                >
                  {{ formatCity(item) }}
                </div>
              </template>
              <span>{{ formatCity(item) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.zip="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div
                  class="text-truncate"
                  style="min-width: 50px; max-width: 120px"
                  v-on="on"
                >
                  {{ formatZip(item) }}
                </div>
              </template>
              <span>{{ formatZip(item) }}</span>
            </v-tooltip>
          </template>

          <template
            v-if="$vuetify.breakpoint.mdAndUp"
            v-slot:item.actions="{ item }"
          >
            <v-btn
              class="lead-list__detail-btn"
              color="primary"
              :to="`/leads/${item.id}`"
              text
            >
              Details
            </v-btn>
          </template>

          <!-- eslint-enable vue/valid-v-slot -->
          <!-- Expanded Item -->
          <template v-slot:expanded-item="{ item }">
            <ExpandedLead
              :lead="item"
              :colspan="headers.length + 1"
              :name="formatName(item)"
              :valuation-count="getValuationCount(item)"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import status from '@/lib/leadStatus'
import { isExpertUser } from '@/lib/productTypes'
import { DEFAULT_HEADERS, BASIC_HEADERS } from '../configs'
import branding from '@/mixins/branding'
import ExpandedLead from './ExpandedLead.vue'
import AvailableStartDate from '@/lib/enums/propertyRequest/AvailableStartDate'
import { ReadableInvitationBlockedReason } from '../enums/Invitation'

const ITEMS_PER_PAGE_OPTIONS = [20, 40, 80]

export default {
  components: { ExpandedLead },

  mixins: [currencyMixin, branding],

  props: {
    leads: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    disableFilters: {
      type: Boolean,
      default: false
    },
    serverItemsLength: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    listOptions: {
      type: Object,
      required: true
    },
    columnSettings: {
      type: Array,
      required: true
    },
    selectItems: {
      type: Function,
      default: () => {}
    },
    activeFiltersCount: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    triggerSelectAllItems: {
      type: Boolean,
      default: false
    },
    triggerDeselectAllItems: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expandedRows: []
    }
  },

  computed: {
    isSmallScreen () {
      return this.$vuetify.breakpoint.lgAndDown
    },
    headers () {
      const header = isExpertUser(this.$auth.user) ? DEFAULT_HEADERS : BASIC_HEADERS

      return [
        ...this.columnSettings,
        // Actions must be visible regardless of settings
        { value: 'actions', visible: true }
      ]
        .filter(({ visible }) => visible)
        .filter(({ hideDisplay }) => !hideDisplay)
        .map(({ value }) => header.find((col) => col.value === value))
    },

    noDataText () {
      return this.activeFiltersCount > 0
        ? 'Keine Kontakte gefunden, die auf die angewendeten Filter oder die Suche passen.'
        : 'Keine Kontakte vorhanden'
    },

    conversionTypes () {
      return Object.values(this.CONVERSION_TYPES).filter(({ type }) => this.isColumnVisible(`conversionCountByType.${type}`))
    }
  },

  watch: {
    triggerSelectAllItems () {
      if (this.triggerSelectAllItems) {
        this.selectAllItems()
      }
    },
    triggerDeselectAllItems () {
      if (this.triggerDeselectAllItems) {
        this.deselectAllItems()
      }
    }
  },

  created () {
    this.leadStatus = status
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS
    this.CONVERSION_TYPES = {
      VALUATION: {
        type: 'VALUATION',
        label: 'Immobilie bewertet',
        icon: (item) => (this.getValuationCount(item) > 1 ? 'mdi-home-group' : 'mdi-home')
      },
      PLOT_VALUATION: {
        type: 'PLOT_VALUATION',
        label: 'Grundstück bewertet',
        icon: () => 'mdi-image-filter-hdr'
      },
      RETURN_CALL: {
        type: 'RETURN_CALL',
        label: 'Telefonische Beratung angefragt',
        icon: () => 'mdi-phone'
      },
      LIFE_ANNUITY: {
        type: 'LIFE_ANNUITY',
        label: 'Leibrenten-Check durchgeführt',
        icon: () => 'mdi-check-circle'
      },
      PARTIAL_SALE: {
        type: 'PARTIAL_SALE',
        label: 'Teilverkaufs-Check durchgeführt',
        icon: () => 'mdi-check-circle'
      },
      PROPERTY_REQUEST: {
        type: 'PROPERTY_REQUEST',
        label: 'Kaufgesuch via Wohntraum Finder eingereicht',
        icon: () => 'mdi-home-search'
      }
    }
  },

  methods: {
    readableInvitationBlockedReason (item) {
      return ReadableInvitationBlockedReason[item.invitationBlockedReason]
    },
    getValuationCount (item) {
      return item?.conversionCountByType?.VALUATION + item?.conversionCountByType?.IN_APP_VALUATION
    },
    getIcon (item, type) {
      return this.CONVERSION_TYPES[type].icon(item)
    },
    showIcon (item, type) {
      return item?.conversionCountByType && item?.conversionCountByType[type] > 0
    },
    showPostalShippingIcon (item) {
      return Boolean(
        item.conversions &&
          item.conversions.find(
            (current) => current.download?.postalShipping && current.download.postalShipping !== 'NONE'
          )
      )
    },
    showQuizPreConversionIcon (item) {
      return Boolean(item.conversions?.some(({ preConversion }) => preConversion?.type === 'QUIZ'))
    },
    showPropertyTrackerIcon (item) {
      return item?.purchaseProperty?.landingpageAccessCode
    },
    showPropertyTrackerFinishedIcon (item) {
      return item?.purchaseProperty?.dossier && item?.purchaseProperty?.dossier?.url
    },
    formatCity (item) {
      const valuationConversion = item.conversions.find((conversion) => conversion.type === 'VALUATION')
      if (valuationConversion) {
        return valuationConversion.valuation.inputs.address.city
      }
      return ''
    },
    formatZip (item) {
      const valuationConversion = item.conversions.find((conversion) => conversion.type === 'VALUATION')
      if (valuationConversion) {
        return valuationConversion.valuation.inputs.address.zip
      }
      return ''
    },
    formatName ({ firstname, lastname }) {
      if (!firstname || !lastname) {
        return ''
      }
      return `${firstname} ${lastname}`
    },
    getInitials ({ firstname, lastname }) {
      return [firstname, lastname]
        .map((str) => (typeof str === 'string' ? str.charAt(0) : ''))
        .join('')
        .toUpperCase()
    },
    getRowClass (item) {
      return {
        'lead-list__item': true,
        'lead-list__item--new': this.$vuetify.breakpoint.mdAndUp && item.currentStatus === 'new',
        'lead-list__item-mobile--new': this.$vuetify.breakpoint.smAndDown && item.currentStatus === 'new'
      }
    },
    toggleRow (item) {
      if (this.expandedRows[0]?.id === item.id) {
        this.expandedRows = []
      } else {
        this.expandedRows = [item]
      }
    },
    isColumnVisible (key) {
      return this.columnSettings.find((setting) => setting.value === key)?.visible
    },
    isPropertyRequestUrgent (lead, type) {
      return Boolean(
        lead.conversions &&
        type === 'PROPERTY_REQUEST' &&
        lead.conversions.find(
          (current) => current.propertyRequest?.availableStartDate && current.propertyRequest.availableStartDate === AvailableStartDate.EARLIEST
        ) &&
        lead.currentStatus === 'new'
      )
    },
    selectAllVisibleItems ({ items = [], value: checked }) {
      this.$emit('allVisibleItemsSelected', { items, checked })
    },
    selectAllItems () {
      this.$refs.table.toggleSelectAll(true)
      this.$emit('allItemsSelected', true)
    },
    deselectAllItems () {
      this.$refs.table.toggleSelectAll(false)
      this.$emit('allItemsSelected', false)
    }
  }
}
</script>
