import { TOOLS_WITH_DOWNLOADS } from '@/lib/tools'

export const persistFilter = (name, value, initialValue) => {
  if (typeof value !== 'undefined' && value !== null) {
    if (!localStorage.getItem(`leadList.filter.${name}`) && initialValue) {
      value = initialValue
    }
    const serialized = typeof value === 'boolean' ? value * 1 : value
    localStorage.setItem(`leadList.filter.${name}`, serialized)
  } else {
    localStorage.removeItem(`leadList.filter.${name}`)
  }
}

export const restoreFilter = (name, parseBoolean, defaultOnUndefined) => {
  try {
    const value = localStorage.getItem(`leadList.filter.${name}`)
    if (value === null && defaultOnUndefined) {
      return defaultOnUndefined
    }
    return parseBoolean ? Boolean(parseInt(value)) : value
  } catch (err) {
    return parseBoolean ? false : null
  }
}

export const restoreAllFilters = ({ isExpert = false, availableTools }) => {
  const filters = {}
  filters.currentStatus = restoreFilter('currentStatus')
  filters.assignedUser = restoreFilter('assignedUser')
  filters.invited = restoreFilter('invited')
  filters.source = restoreFilter('source')
  filters.downloadLandingpage = restoreFilter('downloadLandingpage')
  filters.leadOriginConversion = restoreFilter('leadOriginConversion', true, true)
  filters.leadOriginImport = restoreFilter('leadOriginImport', true, true)
  if (isExpert) filters.postalShipping = restoreFilter('postalShipping', true)
  availableTools.forEach(({ key }) => { filters[key] = restoreFilter(key, true) })
  return filters
}

export const persistAllFilters = ({ isExpert = false, filters, availableTools }) => {
  persistFilter('currentStatus', filters.currentStatus)
  persistFilter('assignedUser', filters.assignedUser)
  persistFilter('invited', filters.invited)
  persistFilter('source', filters.source)
  persistFilter('downloadLandingpage', filters.downloadLandingpage)
  persistFilter('leadOriginConversion', typeof filters.leadOriginConversion === 'undefined' ? true : filters.leadOriginConversion)
  persistFilter('leadOriginImport', typeof filters.leadOriginImport === 'undefined' ? true : filters.leadOriginImport)
  if (isExpert) {
    persistFilter('postalShipping', filters.postalShipping)
  }
  availableTools.forEach(({ key }) => { persistFilter(key, filters[key]) })
}

export const resetAllFilters = () => {
  persistFilter('currentStatus', null)
  persistFilter('assignedUser', null)
  persistFilter('invited', null)
  persistFilter('source', null)
  persistFilter('downloadLandingpage', null)
  persistFilter('leadOriginConversion', true)
  persistFilter('leadOriginImport', true)
  persistFilter('postalShipping', false)
  TOOLS_WITH_DOWNLOADS.forEach(({ key }) => { persistFilter(key, false) })
}

export const getActiveFilterCount = ({ isExpert = false, availableTools }) => {
  const filters = restoreAllFilters({ isExpert, availableTools })
  let count = [
    filters.currentStatus,
    filters.assignedUser,
    filters.postalShipping,
    filters.invited,
    filters.source,
    filters.downloadLandingpage,
    ...availableTools.map(({ key }) => filters[key])
  ].filter(Boolean).length

  if ((filters.leadOriginConversion && !filters.leadOriginImport) || (!filters.leadOriginConversion && filters.leadOriginImport)) {
    count += 1
  }

  return count
}
