export const TOOLS = [
  {
    type: 'RETURN_CALL',
    action: 'vereinbart',
    order: 2,
    icon: 'mdi-phone',
    key: 'returnCalls',
    label: 'Telefonische Beratung'
  },
  {
    type: 'QUIZZARD',
    action: 'durchgeführt',
    order: 4,
    icon: 'mdi-home-analytics',
    key: 'quizPreConversion',
    label: 'Situationsanalyse'
  }
]

export const TOOLS_WITH_DOWNLOADS = [...TOOLS, {
  type: 'DOWNLOAD',
  action: 'heruntergeladen',
  order: 8,
  icon: 'mdi-file-document-outline',
  key: 'downloads',
  label: 'Ratgeber/Checkliste'
}]
